// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dsp-fi-js": () => import("./../src/pages/dsp.fi.js" /* webpackChunkName: "component---src-pages-dsp-fi-js" */),
  "component---src-pages-dsp-sv-js": () => import("./../src/pages/dsp.sv.js" /* webpackChunkName: "component---src-pages-dsp-sv-js" */),
  "component---src-pages-index-fi-js": () => import("./../src/pages/index.fi.js" /* webpackChunkName: "component---src-pages-index-fi-js" */),
  "component---src-pages-index-sv-js": () => import("./../src/pages/index.sv.js" /* webpackChunkName: "component---src-pages-index-sv-js" */),
  "component---src-pages-offert-fi-js": () => import("./../src/pages/offert.fi.js" /* webpackChunkName: "component---src-pages-offert-fi-js" */),
  "component---src-pages-offert-sv-js": () => import("./../src/pages/offert.sv.js" /* webpackChunkName: "component---src-pages-offert-sv-js" */),
  "component---src-pages-thanks-fi-js": () => import("./../src/pages/thanks.fi.js" /* webpackChunkName: "component---src-pages-thanks-fi-js" */),
  "component---src-pages-thanks-sv-js": () => import("./../src/pages/thanks.sv.js" /* webpackChunkName: "component---src-pages-thanks-sv-js" */)
}

